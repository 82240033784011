exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-dark-jsx": () => import("./../../../src/pages/about/about-dark.jsx" /* webpackChunkName: "component---src-pages-about-about-dark-jsx" */),
  "component---src-pages-about-about-light-jsx": () => import("./../../../src/pages/about/about-light.jsx" /* webpackChunkName: "component---src-pages-about-about-light-jsx" */),
  "component---src-pages-app-privacy-policy-jsx": () => import("./../../../src/pages/app-privacy-policy.jsx" /* webpackChunkName: "component---src-pages-app-privacy-policy-jsx" */),
  "component---src-pages-app-terms-jsx": () => import("./../../../src/pages/app-terms.jsx" /* webpackChunkName: "component---src-pages-app-terms-jsx" */),
  "component---src-pages-blog-blog-dark-jsx": () => import("./../../../src/pages/blog/blog-dark.jsx" /* webpackChunkName: "component---src-pages-blog-blog-dark-jsx" */),
  "component---src-pages-blog-blog-light-jsx": () => import("./../../../src/pages/blog/blog-light.jsx" /* webpackChunkName: "component---src-pages-blog-blog-light-jsx" */),
  "component---src-pages-blog-details-blog-details-dark-jsx": () => import("./../../../src/pages/blog-details/blog-details-dark.jsx" /* webpackChunkName: "component---src-pages-blog-details-blog-details-dark-jsx" */),
  "component---src-pages-blog-details-blog-details-light-jsx": () => import("./../../../src/pages/blog-details/blog-details-light.jsx" /* webpackChunkName: "component---src-pages-blog-details-blog-details-light-jsx" */),
  "component---src-pages-blog-grid-blog-grid-dark-jsx": () => import("./../../../src/pages/blog-grid/blog-grid-dark.jsx" /* webpackChunkName: "component---src-pages-blog-grid-blog-grid-dark-jsx" */),
  "component---src-pages-blog-grid-blog-grid-light-jsx": () => import("./../../../src/pages/blog-grid/blog-grid-light.jsx" /* webpackChunkName: "component---src-pages-blog-grid-blog-grid-light-jsx" */),
  "component---src-pages-blog-list-blog-list-dark-jsx": () => import("./../../../src/pages/blog-list/blog-list-dark.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-dark-jsx" */),
  "component---src-pages-blog-list-blog-list-light-jsx": () => import("./../../../src/pages/blog-list/blog-list-light.jsx" /* webpackChunkName: "component---src-pages-blog-list-blog-list-light-jsx" */),
  "component---src-pages-card-privacy-policy-jsx": () => import("./../../../src/pages/card-privacy-policy.jsx" /* webpackChunkName: "component---src-pages-card-privacy-policy-jsx" */),
  "component---src-pages-contact-contact-dark-jsx": () => import("./../../../src/pages/contact/contact-dark.jsx" /* webpackChunkName: "component---src-pages-contact-contact-dark-jsx" */),
  "component---src-pages-contact-contact-light-jsx": () => import("./../../../src/pages/contact/contact-light.jsx" /* webpackChunkName: "component---src-pages-contact-contact-light-jsx" */),
  "component---src-pages-homepage-home-1-dark-jsx": () => import("./../../../src/pages/homepage/home1-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-1-dark-jsx" */),
  "component---src-pages-homepage-home-1-light-jsx": () => import("./../../../src/pages/homepage/home1-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-1-light-jsx" */),
  "component---src-pages-homepage-home-2-dark-jsx": () => import("./../../../src/pages/homepage/home2-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-2-dark-jsx" */),
  "component---src-pages-homepage-home-2-light-jsx": () => import("./../../../src/pages/homepage/home2-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-2-light-jsx" */),
  "component---src-pages-homepage-home-3-dark-jsx": () => import("./../../../src/pages/homepage/home3-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-3-dark-jsx" */),
  "component---src-pages-homepage-home-3-light-jsx": () => import("./../../../src/pages/homepage/home3-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-3-light-jsx" */),
  "component---src-pages-homepage-home-4-dark-jsx": () => import("./../../../src/pages/homepage/home4-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-4-dark-jsx" */),
  "component---src-pages-homepage-home-4-light-jsx": () => import("./../../../src/pages/homepage/home4-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-4-light-jsx" */),
  "component---src-pages-homepage-home-5-dark-jsx": () => import("./../../../src/pages/homepage/home5-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-5-dark-jsx" */),
  "component---src-pages-homepage-home-5-light-jsx": () => import("./../../../src/pages/homepage/home5-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-5-light-jsx" */),
  "component---src-pages-homepage-home-6-dark-jsx": () => import("./../../../src/pages/homepage/home6-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-6-dark-jsx" */),
  "component---src-pages-homepage-home-6-light-jsx": () => import("./../../../src/pages/homepage/home6-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-6-light-jsx" */),
  "component---src-pages-homepage-home-7-dark-jsx": () => import("./../../../src/pages/homepage/home7-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-7-dark-jsx" */),
  "component---src-pages-homepage-home-7-light-jsx": () => import("./../../../src/pages/homepage/home7-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-7-light-jsx" */),
  "component---src-pages-homepage-home-8-dark-jsx": () => import("./../../../src/pages/homepage/home8-dark.jsx" /* webpackChunkName: "component---src-pages-homepage-home-8-dark-jsx" */),
  "component---src-pages-homepage-home-8-light-jsx": () => import("./../../../src/pages/homepage/home8-light.jsx" /* webpackChunkName: "component---src-pages-homepage-home-8-light-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mobile-app-mobile-app-dark-jsx": () => import("./../../../src/pages/mobile-app/mobile-app-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-mobile-app-dark-jsx" */),
  "component---src-pages-mobile-app-mobile-app-light-jsx": () => import("./../../../src/pages/mobile-app/mobile-app-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-mobile-app-light-jsx" */),
  "component---src-pages-mobile-app-portfolio-dark-jsx": () => import("./../../../src/pages/mobile-app/portfolio-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-portfolio-dark-jsx" */),
  "component---src-pages-mobile-app-portfolio-light-jsx": () => import("./../../../src/pages/mobile-app/portfolio-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-portfolio-light-jsx" */),
  "component---src-pages-mobile-app-pricing-plan-dark-jsx": () => import("./../../../src/pages/mobile-app/pricing-plan-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-pricing-plan-dark-jsx" */),
  "component---src-pages-mobile-app-pricing-plan-light-jsx": () => import("./../../../src/pages/mobile-app/pricing-plan-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-pricing-plan-light-jsx" */),
  "component---src-pages-mobile-app-services-dark-jsx": () => import("./../../../src/pages/mobile-app/services-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-services-dark-jsx" */),
  "component---src-pages-mobile-app-services-light-jsx": () => import("./../../../src/pages/mobile-app/services-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-services-light-jsx" */),
  "component---src-pages-mobile-app-shop-dark-jsx": () => import("./../../../src/pages/mobile-app/shop-dark.jsx" /* webpackChunkName: "component---src-pages-mobile-app-shop-dark-jsx" */),
  "component---src-pages-mobile-app-shop-light-jsx": () => import("./../../../src/pages/mobile-app/shop-light.jsx" /* webpackChunkName: "component---src-pages-mobile-app-shop-light-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-project-details-2-project-details-2-dark-jsx": () => import("./../../../src/pages/project-details2/project-details2-dark.jsx" /* webpackChunkName: "component---src-pages-project-details-2-project-details-2-dark-jsx" */),
  "component---src-pages-project-details-2-project-details-2-light-jsx": () => import("./../../../src/pages/project-details2/project-details2-light.jsx" /* webpackChunkName: "component---src-pages-project-details-2-project-details-2-light-jsx" */),
  "component---src-pages-remove-account-guide-jsx": () => import("./../../../src/pages/remove-account-guide.jsx" /* webpackChunkName: "component---src-pages-remove-account-guide-jsx" */),
  "component---src-pages-showcase-3-showcase-3-dark-jsx": () => import("./../../../src/pages/showcase3/showcase3-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-3-showcase-3-dark-jsx" */),
  "component---src-pages-showcase-3-showcase-3-light-jsx": () => import("./../../../src/pages/showcase3/showcase3-light.jsx" /* webpackChunkName: "component---src-pages-showcase-3-showcase-3-light-jsx" */),
  "component---src-pages-showcase-4-showcase-4-dark-jsx": () => import("./../../../src/pages/showcase4/showcase4-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-4-showcase-4-dark-jsx" */),
  "component---src-pages-showcase-4-showcase-4-light-jsx": () => import("./../../../src/pages/showcase4/showcase4-light.jsx" /* webpackChunkName: "component---src-pages-showcase-4-showcase-4-light-jsx" */),
  "component---src-pages-showcase-showcase-dark-jsx": () => import("./../../../src/pages/showcase/showcase-dark.jsx" /* webpackChunkName: "component---src-pages-showcase-showcase-dark-jsx" */),
  "component---src-pages-showcase-showcase-light-jsx": () => import("./../../../src/pages/showcase/showcase-light.jsx" /* webpackChunkName: "component---src-pages-showcase-showcase-light-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-tos-jsx": () => import("./../../../src/pages/tos.jsx" /* webpackChunkName: "component---src-pages-tos-jsx" */),
  "component---src-pages-works-2-works-2-dark-jsx": () => import("./../../../src/pages/works2/works2-dark.jsx" /* webpackChunkName: "component---src-pages-works-2-works-2-dark-jsx" */),
  "component---src-pages-works-2-works-2-light-jsx": () => import("./../../../src/pages/works2/works2-light.jsx" /* webpackChunkName: "component---src-pages-works-2-works-2-light-jsx" */),
  "component---src-pages-works-3-works-3-dark-jsx": () => import("./../../../src/pages/works3/works3-dark.jsx" /* webpackChunkName: "component---src-pages-works-3-works-3-dark-jsx" */),
  "component---src-pages-works-3-works-3-light-jsx": () => import("./../../../src/pages/works3/works3-light.jsx" /* webpackChunkName: "component---src-pages-works-3-works-3-light-jsx" */),
  "component---src-pages-works-works-dark-jsx": () => import("./../../../src/pages/works/works-dark.jsx" /* webpackChunkName: "component---src-pages-works-works-dark-jsx" */),
  "component---src-pages-works-works-light-jsx": () => import("./../../../src/pages/works/works-light.jsx" /* webpackChunkName: "component---src-pages-works-works-light-jsx" */)
}

